import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import qs from "qs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

const toListingsDateField = (value) => {
  if (!value) {
    return undefined;
  }
  switch (value) {
    case "Created":
      return "created";
    case "Reviewed":
      return "reviewDate";
    case "Filing":
      return "complaintFiledDate";
    case "Takedown":
      return "takedownDate";
    default:
      return value;
  }
};

const toComplaintsDateField = (value) => {
  if (!value) {
    return undefined;
  }
  switch (value) {
    case "Created":
      return "created";
    case "Filing":
      return "filingDate";
    case "Takedown":
      return "takedownDate";
    case "Search":
      return "searchDate";
    default:
      return value;
  }
};

const toDomainsDateField = (value) => {
  if (!value) {
    return undefined;
  }
  switch (value) {
    case "Created":
      return "created";
    case "Filing":
      return "complaintFiled";
    case "Takedown":
      return "takedownDate";
    default:
      return value;
  }
};

const toTestPurchaseDateField = (value) => {
  if (!value) {
    return undefined;
  }
  switch (value) {
    case "Created":
      return "created";
    case "Assigned":
      return "assigned";
    case "Closed":
      return "closed";
    case "Due":
      return "due";
    default:
      return value;
  }
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseUrl:
      process.env.REACT_APP_API_TARGET ||
      `${window.location.protocol}//${window.location.hostname}`,
  }),
  tagTypes: [
    "Metrics",
    "User",
    "Listings",
    "Complaints",
    "SimilarListings",
    "UserNames",
    "Sellers",
    "Tags",
    "ASINTracks",
    "Messages",
    "Users",
    "Images",
    "Domains",
    "UserBrands",
    "Advertisements",
    "Investigations",
  ],
  endpoints: (builder) => ({
    getListings: builder.query({
      query: (params) => ({
        url: `/api/v1/store/listings/page`,
        method: "get",
        params: {
          order: params.order,
          sortBy: params.sortBy,
          date: dayjs(params.start).startOf("day").format(),
          endDate: dayjs(params.end).endOf("day").format(),
          product: params.products.join(","),
          dateField: toListingsDateField(params.dateField),
          field: params.field,
          contains: params.value || "",
          seller: params.seller || "",
          brand: params.brands.join(","),
          country: params.countries.join(","),
          productCategory: params.categories.join(","),
          platform: params.marketplaces.join(","),
          channel: params.channel.join(","),
          listingStatus: params.listingStatus.join(","),
          toi: params.infringementTypes.join(","),
          size: params.limit,
          page: params.page,
          state: params.state.join(","),
          testPurchase: params.testPurchase,
          filed: params.filed,
          tags: params.tags.join(","),
          _id: params._id,
        },
      }),
      providesTags: ["Listings"],
    }),
    getListingById: builder.query({
      query: (id) => ({
        url: `/api/v1/store/listings/${id}`,
        method: "get",
      }),
    }),
    getListingsById: builder.query({
      query: (params) => ({
        url: "/api/v1/store/listings/byIds",
        method: "get",
        params: params,
      }),
      providesTags: ["SimilarListings"],
    }),
    getBrandCountries: builder.query({
      query: (params) => ({
        url: `/api/v1/store/listings/countries`,
        method: "get",
        params: params,
      }),
    }),
    getBrandProducts: builder.query({
      query: (params) => ({
        url: `/api/v1/store/listings/products`,
        method: "get",
        params: params,
      }),
    }),
    getMarketPlaces: builder.query({
      query: (params) => ({
        url: "/api/v1/store/dashboard/marketplaces",
        method: "get",
        body: params,
      }),
    }),
    getMarketplaceByIdentifier: builder.query({
      query: (param) => ({
        url: `/api/v1/store/marketplaces/identifier/${param}`,
        method: "get",
      }),
    }),
    getAllMarketPlaces: builder.query({
      query: () => ({
        url: "/api/v1/store/dashboard/all_marketplaces",
        method: "get",
      }),
    }),
    getCrawlerMarketplaces: builder.query({
      query: () => ({
        url: `/api/v1/store/marketplaces/crawled`,
        method: "get",
      }),
    }),
    getSellerByMarketplace: builder.query({
      query: (params) => ({
        url: `/api/v1/store/sellers/marketplace/${params.marketplace}/identifiers/${params.seller}`,
        method: "get",
      }),
    }),
    getProductsByBrand: builder.query({
      query: (params) => ({
        url: `/api/v1/store/products/brand/${params.brand}`,
        method: "get",
      }),
    }),
    getSellerStats: builder.query({
      query: (params) => ({
        url: `/api/v1/store/dashboard/sellers/${params.seller}/marketplace/${params.marketplace}/stats`,
        method: "get",
      }),
    }),
    getSellerAliases: builder.query({
      query: (params) => ({
        url: `/api/v1/store/dashboard/sellers/${params.seller}/marketplace/${params.marketplace}/aliases`,
        method: "get",
      }),
    }),
    getSellerImageCluster: builder.query({
      query: (params) => ({
        url: `/api/v1/store/dashboard/sellers/${params.seller}/marketplace/${params.marketplace}/imagecluster`,
        method: "get",
      }),
    }),
    getImageCluster: builder.query({
      query: (params) => ({
        url: `/api/v1/store/clusters/image`,
        method: "get",
        params: params,
      }),
    }),
    getSellerListings: builder.query({
      query: (params) => ({
        url: `/api/v1/store/dashboard/sellers/${params.seller}/marketplace/${params.marketplace}/listings`,
        method: "get",
      }),
    }),
    getSellerComplaints: builder.query({
      query: (params) => ({
        url: `/api/v1/store/dashboard/sellers/${params.seller}/marketplace/${params.marketplace}/complaints`,
        method: "get",
      }),
    }),
    getCountries: builder.query({
      query: (params) => ({
        url: "/api/v1/store/dashboard/countries",
        method: "get",
        params: params,
      }),
    }),
    getAllCountries: builder.query({
      query: () => ({
        url: "/api/v1/store/dashboard/all_countries",
        method: "get",
      }),
    }),
    getUserBrands: builder.query({
      query: () => ({
        url: "/api/v1/store/brands/user",
        method: "get",
      }),
      providesTags: ["UserBrands"],
    }),
    getMetrics: builder.mutation({
      query: (body) => ({
        url: "/api/v1/store/dashboard/metrics",
        method: "post",
        data: body,
      }),
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/api/v1/store/users`,
        method: "put",
        data: user,
      }),
      invalidatesTags: ["User"],
    }),
    getMonthlyInfringings: builder.mutation({
      query: (params) => ({
        url: "/api/v1/store/dashboard/infringing/monthly",
        method: "post",
        data: params,
      }),
    }),
    getMetricsInfringings: builder.mutation({
      query: (params) => ({
        url: "/api/v1/store/dashboard/infringing/metrics",
        method: "post",
        data: params,
      }),
    }),
    getMetricsInfringingSellers: builder.mutation({
      query: (params) => ({
        url: "/api/v1/store/dashboard/infringing/seller/metrics",
        method: "post",
        data: params,
      }),
    }),
    getSellerMetrics: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/dashboard/infringing/seller/${params.id}/metrics`,
        method: "post",
        data: params,
      }),
    }),
    getGlobalInfringings: builder.mutation({
      query: (params) => ({
        url: "/api/v1/store/dashboard/infringing/global",
        method: "post",
        data: params,
      }),
    }),
    updateListingField: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/field`,
        method: "put",
        data: body,
      }),
      invalidatesTags: ["Listings", "SimilarListings"],
    }),
    getComplaints: builder.query({
      query: (params) => ({
        url: `/api/v1/store/complaints/page`,
        method: "get",
        params: {
          order: params.order,
          sortBy: params.sortBy,
          dateField: toComplaintsDateField(params.dateField),
          date: dayjs(params.start).startOf("day").format(),
          endDate: dayjs(params.end).endOf("day").format(),
          field: params.field,
          contains: params.value,
          seller: params.seller,
          brand: params.brands.join(","),
          country: params.countries.join(","),
          marketplace: params.marketplaces.join(","),
          size: params.limit,
          page: params.page,
          status: params.status?.join(","),
          type: params.infringementTypes?.join(","),
        },
      }),
      providesTags: ["Complaints"],
    }),
    fileComplaint: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/complaints/${params.complaint}/file`,
        method: "post",
        params: params,
      }),
      invalidatesTags: ["Complaints"],
    }),
    takedownComplaint: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/complaints/${params.complaint}/takedown/${params.date}`,
        method: "post",
      }),
      invalidatesTags: ["Complaints"],
    }),
    flagImage: builder.mutation({
      query: (body) => ({
        url: `/api/v1/scores/images/cluster`,
        method: "post",
        data: body,
      }),
    }),
    makeTestPurchase: builder.mutation({
      query: (body) => ({
        url: "/api/v1/store/cases",
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Listings"],
    }),
    getTestPurchase: builder.query({
      query: (param) => ({
        url: `/api/v1/store/cases/${param}`,
        method: "get",
      }),
      providesTags: ["Investigations"],
    }),
    getUserById: builder.query({
      query: (param) => ({
        url: `/api/v1/store/users/provider/${param}`,
        method: "get",
      }),
      providesTags: ["User"],
    }),
    deleteListings: builder.mutation({
      query: (ids) => ({
        url: `/api/v1/store/listings`,
        method: "DELETE",
        params: { ids: ids.join("::") },
      }),
      invalidatesTags: ["Listings"],
    }),
    clusterListings: builder.mutation({
      query: (body) => ({
        url: `/api/v1/scores/text/cluster`,
        method: "post",
        data: body,
      }),
    }),
    takeScreenshot: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/screenshots/capture`,
        method: "post",
        data: body,
      }),
    }),
    addScreenshot: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/screenshots`,
        method: "put",
        data: body,
      }),
    }),
    addComplaint: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/complaints`,
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Listings"],
    }),
    getUserNames: builder.query({
      query: () => ({
        url: `/api/v1/store/users/names`,
        method: "get",
      }),
      providesTags: ["UserNames"],
    }),
    getUsers: builder.query({
      query: () => ({
        url: `/api/v1/store/users/list`,
        method: "get",
      }),
      providesTags: ["Users"],
    }),
    analyzeImage: builder.query({
      query: (params) => ({
        url: `/api/v1/images/analyze`,
        method: "get",
        params: params,
      }),
      providesTags: ["Images"],
    }),
    getSellers: builder.query({
      query: (params) => ({
        url: `/api/v1/store/sellers/page`,
        method: "get",
        params: {
          order: params.order,
          sortBy: params.sortBy,
          field: params.field,
          contains: params.value,
          seller: params.seller,
          brands: params.brands.join(","),
          country: params.countries.join(","),
          marketplace: params.marketplaces.join(","),
          size: params.limit,
          page: params.page,
          status: params.status?.join(","),
          type: params.infringementTypes?.join(","),
        },
      }),
      providesTags: ["Sellers"],
    }),
    getAllTags: builder.query({
      query: () => ({
        url: `/api/v1/store/listings/tags`,
        method: "get",
      }),
      providesTags: ["Tags"],
    }),
    updateTags: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/tags`,
        method: "put",
        data: body,
      }),
      invalidatesTags: ["Tags"],
    }),
    getASIN: builder.query({
      query: (param) => ({
        url: `/api/v1/store/asins/asin/${param}`,
        method: "get",
      }),
      providesTags: ["ASINTracks"],
    }),
    trackASIN: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/asins/${params.listing}/track/${params.until}/location/${params.location}`,
        method: "post",
      }),
      invalidatesTags: ["Listings"],
    }),
    toggleAsinAlert: builder.mutation({
      query: (param) => ({
        url: `/api/v1/store/asins/${param}/alert`,
        method: "post",
      }),
      invalidatesTags: ["ASINTracks"],
    }),
    getUserMessages: builder.query({
      query: () => ({
        url: `/api/v1/store/users/messages`,
        method: "get",
      }),
      providesTags: ["Messages"],
    }),
    addListingComment: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/comment`,
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Listings"],
    }),
    addListingReviewer: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/listings/review`,
        method: "post",
        data: body,
      }),
    }),
    getImages: builder.query({
      query: (params) => ({
        url: `/api/v1/store/images/page`,
        method: "get",
        params: params,
      }),
      providesTags: ["Images"],
    }),
    deleteUserMessage: builder.mutation({
      query: (id) => ({
        url: `/api/v1/store/users/messages/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Messages"],
    }),
    crawlRequest: builder.mutation({
      query: (body) => ({
        url: `/api/v1/importer/crawls`,
        method: "post",
        data: body,
      }),
    }),
    getListingComplaint: builder.query({
      query: (param) => ({
        url: `/api/v1/store/complaints/byListing?listing=${param}`,
        method: "get",
      }),
      providesTags: ["Images"],
    }),
    getMetaComplaint: builder.query({
      query: (param) => ({
        url: `/api/v1/store/complaints/${param}/meta`,
        method: "get",
      }),
    }),
    uploadPurchaseFile: builder.mutation({
      query: (data) => ({
        url: `/api/v1/images/purchase`,
        method: "post",
        data: data,
      }),
    }),
    uploadScreenshot: builder.mutation({
      query: (data) => ({
        url: `/api/v1/images/screenshots`,
        method: "post",
        data: data,
      }),
    }),
    addTestPurchaseComment: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/cases/${body.id}/comment`,
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Investigations"],
    }),
    deleteTestPurchase: builder.mutation({
      query: (id) => ({
        url: `/api/v1/store/cases/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Listings", "Investigations"],
    }),
    getDomains: builder.query({
      query: (params) => ({
        url: `/api/v1/store/domains/page`,
        method: "get",
        params: {
          order: params?.order || "asc",
          sortBy: params?.sortBy || "name",
          field: params?.field || "name",
          contains: params?.contains || "",
          size: params?.limit || 10,
          page: params?.page || 0,
          dateField: toDomainsDateField(params.dateField),
          date: dayjs(params.start).startOf("day").format(),
          endDate: dayjs(params.end).endOf("day").format(),
          complaintStatus: params?.complaintStatus,
          brands: params?.brands.join(","),
        },
      }),
      providesTags: ["Domains"],
    }),
    getAllDomainStats: builder.query({
      query: () => ({
        url: `/api/v1/store/dashboard/metrics/domain`,
        method: "get",
      }),
    }),
    collectDomainStats: builder.mutation({
      query: (param) => ({
        url: `/api/v1/imports/domains/${param}/stats`,
        method: "get",
      }),
      invalidatesTags: ["Domains"],
    }),
    getDomainStats: builder.query({
      query: (param) => ({
        url: `/api/v1/store/dashboard/domains/${param}/stats`,
        method: "get",
      }),
    }),
    getScreenshotBaseUrl: builder.query({
      query: () => ({
        url: `/api/v1/screenshots/base`,
        method: "get",
      }),
    }),
    addDomain: builder.mutation({
      query: (data) => ({
        url: `/api/v1/store/domains`,
        method: "post",
        data: data,
      }),
    }),
    scoreListing: builder.mutation({
      query: (param) => ({
        url: `/api/v1/scores/${param}`,
        method: "post",
      }),
    }),
    getComplaintListings: builder.query({
      query: (param) => ({
        url: `/api/v1/store/listings/complaint/${param}`,
        method: "get",
      }),
    }),
    deleteComplaint: builder.mutation({
      query: (id) => ({
        url: `/api/v1/store/complaints/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Complaints"],
    }),
    getCategories: builder.query({
      query: (params) => ({
        url: "/api/v1/store/dashboard/categories",
        method: "get",
        params: params,
      }),
    }),
    getInvestigations: builder.query({
      query: (params) => ({
        url: `/api/v1/store/cases/page`,
        method: "get",
        params: {
          order: params.order,
          sortBy: params.sortBy,
          dateField: toTestPurchaseDateField(params.dateField),
          date: dayjs(params.start).startOf("day").format(),
          endDate: dayjs(params.end).endOf("day").format(),
          field: params.field,
          contains: params.value || "",
          seller: params.seller || "",
          status: params.purchaseStatus.join(","),
          brand: params.brands.join(","),
          country: params.countries.join(","),
          platform: params.marketplaces.join(","),
          toi: params.infringementTypes.join(","),
          assignedTo: params.assigned.join(","),
          size: params.limit,
          page: params.page,
          state: params.state.join(","),
        },
      }),
      providesTags: ["Investigations"],
    }),
    updatePurchaseStatus: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/cases/${params.id}/status/${params.status}`,
        method: "put",
      }),
      invalidatesTags: ["Investigations"],
    }),
    assignTestPurchase: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/cases/${params.id}/assign`,
        method: "put",
        data: params.user,
      }),
      invalidatesTags: ["Investigations"],
    }),
    setWatchers: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/asins/${params.id}/watchers`,
        method: "put",
        data: params.watchers,
      }),
      invalidatesTags: ["ASINTracks"],
    }),
    updateSellerField: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/sellers/${params.id}/field`,
        method: "put",
        data: params.body,
      }),
      invalidatesTags: ["Sellers"],
    }),
    analyzeListingText: builder.query({
      query: (params) => ({
        url: `/api/v1/scores/openai`,
        method: "get",
        params: params,
      }),
    }),
    getProduct: builder.query({
      query: (params) => ({
        url: `/api/v1/store/products/name/${params.product}/brand/${params.brand}`,
        method: "get",
      }),
    }),
    updateDomainBrands: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/domains/brands`,
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Domains"],
    }),
    updateDomain: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/domains`,
        method: "put",
        data: body,
      }),
      invalidatesTags: ["Domains"],
    }),
    deleteScreenshot: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/listings/${params.listing}/screenshots/${params.screenshot}`,
        method: "delete",
      }),
      invalidatesTags: ["Listings"],
    }),
    deleteComment: builder.mutation({
      query: (params) => ({
        url: `/api/v1/store/listings/${params.listing}/comments/${params.comment}`,
        method: "delete",
      }),
      invalidatesTags: ["Listings"],
    }),
    deleteAdvertisement: builder.mutation({
      query: (id) => ({
        url: `/api/v1/store/ads/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Advertisements"],
    }),
    getAdvertisements: builder.query({
      query: (params) => ({
        url: `/api/v1/store/ads/page`,
        method: "get",
        params: {
          order: params.order,
          sortBy: params.sortBy,
          field: params.field,
          contains: params.value,
          seller: params.seller,
          brands: params.brands.join(","),
          country: params.countries.join(","),
          marketplace: params.marketplaces.join(","),
          size: params.limit,
          page: params.page,
          status: params.status?.join(","),
          type: params.infringementTypes?.join(","),
        },
      }),
      providesTags: ["Advertisements"],
    }),
    updateInvestigation: builder.mutation({
      query: (body) => ({
        url: `/api/v1/store/cases`,
        method: body.id ? "put" : "post",
        data: body,
      }),
      invalidatesTags: ["Investigations"],
    }),
    getInfringers: builder.mutation({
      query: (body) => ({
        url: "/api/v1/store/dashboard/metrics/infringers",
        method: "post",
        data: body,
      }),
    }),
    getPlatformInfringings: builder.mutation({
      query: (params) => ({
        url: "/api/v1/store/dashboard/infringing/platform",
        method: "post",
        data: params,
      }),
    }),
    deleteListingImage: builder.mutation({
      query: (data) => ({
        url: `/api/v1/store/listings/${data.listing}/images`,
        method: "delete",
        data: { images: [data.image] },
      }),
      invalidatesTags: ["Listings"],
    }),
    addListingImages: builder.mutation({
      query: (data) => ({
        url: `/api/v1/store/listings/${data.listing}/images`,
        method: "put",
        data: { images: data.images },
      }),
      invalidatesTags: ["Listings"],
    }),
    uploadListingImageFile: builder.mutation({
      query: (args) => ({
        url: `/api/v1/media/upload`,
        method: "post",
        data: args.data,
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      }),
    }),
    updateDomainStatus: builder.mutation({
      query: (id) => ({
        url: `/api/v1/imports/domains/${id}/update`,
        method: "put",
      }),
    }),
    deleteDomain: builder.mutation({
      query: (id) => ({
        url: `/api/v1/store/domains/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Domains"],
    }),
    updateBrand: builder.mutation({
      query: (brand) => ({
        url: `/api/v1/store/brands`,
        method: "put",
        data: brand,
      }),
      invalidatesTags: ["UserBrands"],
    }),
  }),
});

export const {
  useGetListingsQuery,
  useGetListingByIdQuery,
  useGetListingsByIdQuery,
  useUpdateUserMutation,
  useGetBrandCountriesQuery,
  useGetBrandProductsQuery,
  useGetMarketPlacesQuery,
  useGetMarketplaceByIdentifierQuery,
  useGetAllMarketPlacesQuery,
  useGetCrawlerMarketplacesQuery,
  useGetSellerByMarketplaceQuery,
  useGetProductsByBrandQuery,
  useLazyGetProductsByBrandQuery,
  useGetSellerStatsQuery,
  useGetSellerAliasesQuery,
  useGetSellerImageClusterQuery,
  useGetImageClusterQuery,
  useGetSellerListingsQuery,
  useGetSellerComplaintsQuery,
  useGetCountriesQuery,
  useGetAllCountriesQuery,
  useGetUserBrandsQuery,
  useGetMetricsMutation,
  useGetMonthlyInfringingsMutation,
  useGetMetricsInfringingsMutation,
  useGetMetricsInfringingSellersMutation,
  useGetSellerMetricsMutation,
  useGetGlobalInfringingsMutation,
  useUpdateListingFieldMutation,
  useGetComplaintsQuery,
  useFileComplaintMutation,
  useTakedownComplaintMutation,
  useFlagImageMutation,
  useMakeTestPurchaseMutation,
  useGetTestPurchaseQuery,
  useGetUserByIdQuery,
  useDeleteListingsMutation,
  useClusterListingsMutation,
  useTakeScreenshotMutation,
  useAddScreenshotMutation,
  useAddComplaintMutation,
  useGetUserNamesQuery,
  useGetUsersQuery,
  useLazyAnalyzeImageQuery,
  useGetSellersQuery,
  useGetAllTagsQuery,
  useUpdateTagsMutation,
  useGetASINQuery,
  useTrackASINMutation,
  useToggleAsinAlertMutation,
  useGetUserMessagesQuery,
  useAddListingCommentMutation,
  useAddListingReviewerMutation,
  useGetImagesQuery,
  useDeleteUserMessageMutation,
  useCrawlRequestMutation,
  useGetListingComplaintQuery,
  useGetMetaComplaintQuery,
  useUploadPurchaseFileMutation,
  useUploadScreenshotMutation,
  useAddTestPurchaseCommentMutation,
  useDeleteTestPurchaseMutation,
  useGetDomainsQuery,
  useGetAllDomainStatsQuery,
  useCollectDomainStatsMutation,
  useGetDomainStatsQuery,
  useGetScreenshotBaseUrlQuery,
  useAddDomainMutation,
  useScoreListingMutation,
  useGetComplaintListingsQuery,
  useDeleteComplaintMutation,
  useGetCategoriesQuery,
  useGetInvestigationsQuery,
  useUpdatePurchaseStatusMutation,
  useAssignTestPurchaseMutation,
  useSetWatchersMutation,
  useUpdateSellerFieldMutation,
  useAnalyzeListingTextQuery,
  useGetProductQuery,
  useUpdateDomainBrandsMutation,
  useUpdateDomainMutation,
  useDeleteScreenshotMutation,
  useDeleteCommentMutation,
  useGetAdvertisementsQuery,
  useDeleteAdvertisementMutation,
  useUpdateInvestigationMutation,
  useGetInfringersMutation,
  useGetPlatformInfringingsMutation,
  useDeleteListingImageMutation,
  useAddListingImagesMutation,
  useUploadListingImageFileMutation,
  useUpdateDomainStatusMutation,
  useDeleteDomainMutation,
  useUpdateBrandMutation,
} = api;
