import axios from "axios";
import { isValidToken } from "../hooks/utils/jwt";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_TARGET ||
    `${window.location.protocol}//${window.location.hostname}`,
});

axios.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("accessToken");
  if (token && isValidToken(token)) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
