import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {
  useAddComplaintMutation,
  useGetListingComplaintQuery,
  useGetProductQuery,
  useGetUserBrandsQuery,
} from "../../../store/api";
import {
  COMPLAINT_DESCRIPTION_TEMPLATES,
  getIprLabel,
  INFRINGEMENT_REASONS,
} from "../../../utils/labels";

export default function InfringmentDialog(props) {
  const { selected, type, listings, handleClose } = props;

  const getDescription = (key) => {
    let brand = listings
      .filter((l) => selected.includes(l._id))
      .map((s) => s.brand)[0];
    switch (key) {
      case "CFT_1":
        return `The counterfeit product in the listing(s) are neither manufactured by ${brand.toUpperCase()} nor retailed by their authorized distributors as there is a clear variation in the product Photo/video/post in comparison with the original product. Therefore, the goods offered for sale in these listings are confirmed counterfeits and these listings should be taken down accordingly. Let me know if you have any further questions or if there's anything else I can assist you with.`;
      case "TM_1":
        return `The product in the reported listing infringes on our client’s registered design(s). The unauthorized use of our client’s ${brand.toUpperCase()} design is a clear violation of their intellectual property rights. Therefore, the listing should be removed.`;
      case "TM_2":
        return `The seller in the reported listing is copying/reproducing our client’s registered copyright without authorization. Such unauthorized use of our client’s registered work infringes upon their copyright. Therefore, the listings should be taken down accordingly.`;
      case "TM":
        return `The product in the reported listing bearing our client’s registered trademark and logo has not been manufactured by our client. There are clear variations in the features of the product in the listing in comparison with the original product of our client. We thus have a good faith belief that the product offered for sale is a counterfeit and, the listing(s) should be taken down accordingly.`;
      case "CR_2":
        return `The reported listing features at least one image in which our client owns the copyright (The original image of our client can be viewed at ${brand.toUpperCase()}). The copyrighted image has been used by the seller without any permission or license and is infringing upon our client’s copyright.  Hence, the listing must be taken down.`;
      case "US_1":
        return `The reported listing is an unauthorized sale of testers.`;
      case "UP_1":
        return `The seller in the reported listing is offering for sale combustible cigarettes bearing our client’s registered trademark ${brand.toUpperCase()} without authorization. The sale of this type of goods is a clear violation of platform's ‘Restricted Goods and Services’ policy and therefore, the listing should be removed.`;
      case "UP_2":
        return `The reported account in its name is using our client’s registered trademark ${brand.toUpperCase()}, without authorization. It creates confusion among the public that the account is endorsed by/or affiliated with our client, which is not the case. Therefore, the account should be taken down.`;
      case "UP_3":
        return `The seller in the reported listing is using the term ‘cartouche’ in the title, which translates to ‘cartridge’, and is deliberately using random crop images to avoid detection by the platform. Through direct messaging with the seller we were able to confirmed that they are dealing in the sale of combustible cigarette packs bearing our client’s registered trademark ${brand.toUpperCase()}. This type of goods are prohibited for sale on the platform and is a clear violation of the platforms’ ‘Restricted Goods and Services’ policy. Therefore, the listing should be removed.`;
      case "UR_1":
        return `The listing contains a legal or regulatory violation.`;
      default:
        return "";
    }
  };

  const { data: brands } = useGetUserBrandsQuery();
  const { data: complaint } = useGetListingComplaintQuery(selected[0]);
  const [state, setState] = React.useState(type);
  const [ipr, setIpr] = React.useState(null);
  const [iprs, setIprs] = React.useState(undefined);
  const [description, setDescription] = React.useState("");
  const [reason, setReason] = React.useState(
    type === "CFT" ? "COUNTERFEIT" : ""
  );
  const [newImage, setNewImage] = React.useState(undefined);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [selectedProductImage, setSelectedProductImage] =
    React.useState(undefined);
  const [doNotFile, setDoNotFile] = React.useState(false);
  React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [addComplaint, { isLoading: isUpdating }] = useAddComplaintMutation();
  const [template, setTemplate] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const { data: product } = useGetProductQuery({
    product: listings.find((l) => l._id === selected[0]).product,
    brand: listings.find((l) => l._id === selected[0]).brand,
  });

  React.useEffect(() => {
    if (complaint) {
      setDescription(complaint.description);
      iprs &&
        setIpr({ label: iprs.get(complaint.ipr), id: complaint.ipr } || null);
      setReason(complaint.reason || "");
    }
  }, [complaint, iprs]);

  React.useEffect(() => {
    switch (state) {
      case "CR":
        setSubmitDisabled(
          !description ||
            reason === "" ||
            (!ipr && reason === "REG_COPYRIGHT_MATCH")
        );
        break;
      case "UA":
      case "DE":
      case "TM":
      case "CFT":
        setSubmitDisabled(!description || !ipr);
        break;
      case "US":
        setSubmitDisabled(!description || reason === "");
        break;
      default:
        setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [description, reason, ipr, state]);

  let images = [];
  listings.forEach((l) => {
    if (selected.includes(l._id)) {
      images = images.concat(l.images);
    }
  });

  const handleSubmit = () => {
    let images = undefined;
    if (selectedImages.length > 0) {
      images = [];
      selectedImages.forEach((i) => {
        images.push({ used: i, copyright: selectedProductImage });
      });
    }
    let data = {
      listings: selected,
      type: state,
      status: "NEW",
      images: images,
      reason: reason,
      description: description,
      doNotFile: doNotFile,
      sellers: Array.from(
        new Set(
          listings.filter((l) => selected.includes(l._id)).map((s) => s.seller)
        )
      ),
      brand: listings
        .filter((l) => selected.includes(l._id))
        .map((s) => s.brand)[0], // pick first
      product: listings
        .filter((l) => selected.includes(l._id))
        .map((s) => s.product)[0], // pick first
      marketplace: listings
        .filter((l) => selected.includes(l._id))
        .map((s) => s.platform)[0], // pick first
      ipr: ipr !== null ? ipr.id : undefined,
    };
    addComplaint(data);
    handleClose();
  };

  React.useEffect(() => {
    if (brands) {
      let list = brands
        .find((b) => b.name === listings[0].brand)
        .details?.filter(
          (detail) =>
            detail.type === state || (detail.type === "TM" && state === "CFT")
        );
      let labels = new Map();
      list?.forEach((detail) => {
        labels.set(detail.uid, getIprLabel(detail));
      });
      setIprs(labels);
    }

    // eslint-disable-next-line
  }, [state, brands]);

  const getIprSelect = () => {
    let menuItems = [];
    iprs?.forEach((value, key) => {
      menuItems.push({ label: value, id: key });
    });

    return (
      <FormControl fullWidth sx={{ mt: 5 }}>
        <Autocomplete
          fullWidth
          disablePortal
          options={menuItems}
          value={ipr}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            setIpr(newValue);
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Property Rights (required)" />
          )}
        />
      </FormControl>
    );
  };

  const getTemplateSelect = () => {
    let menuItems = [];
    COMPLAINT_DESCRIPTION_TEMPLATES.filter((t) => t.reason === reason).forEach(
      (t) => {
        menuItems.push(
          <MenuItem key={t.value} value={t.value}>
            {t.text}
          </MenuItem>
        );
      }
    );
    return (
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="desc-select-label">Complaint Templates</InputLabel>
        <Select
          labelId="desc-select-label"
          id="desc-select"
          value={template}
          onChange={(event) => {
            setTemplate(event.target.value);
            setDescription(getDescription(event.target.value));
          }}
          autoWidth
          label="Complaint Templates"
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  };

  return (
    <div>
      <Dialog open={selected !== undefined} onClose={handleClose}>
        <DialogTitle>Infringement Classification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete the form below to create a complaint for the selected
            listings:
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel id="type-label">Infringement</InputLabel>
            <Select
              labelId="type-label"
              id="type-select"
              value={state}
              onChange={(event) => {
                setIpr(null);
                setReason("");
                setDescription("");
                setState(event.target.value);
              }}
              autoWidth
              label="Infringement"
            >
              <MenuItem key={"CR"} value={"CR"}>
                Copyright
              </MenuItem>
              <MenuItem key={"CFT"} value={"CFT"}>
                Counterfeit
              </MenuItem>
              <MenuItem key={"DE"} value={"DE"}>
                Design
              </MenuItem>
              <MenuItem key={"TM"} value={"TM"}>
                Trademark
              </MenuItem>
              <MenuItem key={"US"} value={"US"}>
                Unauthorized Sale
              </MenuItem>
            </Select>
          </FormControl>
          {state !== "CFT" && (
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="cr-reasons-label">Reason (required)</InputLabel>
              <Select
                labelId="cr-reasons-label"
                id="cr-reasons-select"
                value={reason}
                onChange={(event) => {
                  setReason(event.target.value);
                  setTemplate("");
                  setDescription("");
                }}
                autoWidth
                label="Reason (required)"
              >
                {INFRINGEMENT_REASONS.filter((r) => r.toi === state).map(
                  (cr) => {
                    return (
                      <MenuItem key={cr.value} value={cr.value}>
                        {cr.text}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          )}
          {getTemplateSelect()}
          <FormControl fullWidth sx={{ mt: 3 }} label="Description (required)">
            <TextField
              fullWidth
              id="description"
              placeholder="Complaint Description (required)"
              multiline
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              maxRows={6}
              minRows={4}
            />
          </FormControl>
          {state !== "US" &&
            (state !== "CR" ||
              (state === "CR" && reason === "REG_COPYRIGHT_MATCH")) &&
            getIprSelect()}
          <Typography sx={{ mt: 5 }}>Select images to include:</Typography>
          <ImageList sx={{ height: 350 }} cols={3}>
            {images.map((item, index) => (
              <ImageListItem key={index}>
                <img src={`${item}`} alt={item} loading="lazy" height={200} />
                <ImageListItemBar
                  title={"Select to include"}
                  subtitle={item}
                  actionIcon={
                    <IconButton
                      onClick={() => {
                        if (selectedImages.includes(item)) {
                          const updated = selectedImages.filter(
                            (i) => i !== item
                          );
                          setSelectedImages(updated);
                        } else {
                          setSelectedImages(selectedImages.concat([item]));
                        }
                      }}
                    >
                      {selectedImages.includes(item) ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="primary" />
                      )}
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          {state === "CR" && product?.images && product.images.length > 0 && (
            <div>
              <Typography sx={{ mt: 5 }}>
                Select official image to include:
              </Typography>
              <ImageList sx={{ height: 350 }} cols={3}>
                {!newImage &&
                  product.images.map((item, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={`${item}`}
                        alt={item}
                        loading="lazy"
                        height={200}
                      />
                      <ImageListItemBar
                        title={"Select to include"}
                        subtitle={item}
                        actionIcon={
                          <IconButton
                            onClick={() => {
                              setSelectedProductImage(item);
                            }}
                          >
                            {selectedProductImage === item ? (
                              <CheckBoxIcon color="primary" />
                            ) : (
                              <CheckBoxOutlineBlankIcon color="primary" />
                            )}
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
                {newImage && (
                  <ImageListItem key={-1}>
                    <img
                      src={`${newImage}`}
                      alt={newImage}
                      loading="lazy"
                      height={200}
                    />
                    <ImageListItemBar
                      title={"Select to include"}
                      subtitle={newImage}
                      actionIcon={
                        <IconButton
                          onClick={() => {
                            setSelectedProductImage(newImage);
                          }}
                        >
                          {selectedProductImage === newImage ? (
                            <CheckBoxIcon color="primary" />
                          ) : (
                            <CheckBoxOutlineBlankIcon color="primary" />
                          )}
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                )}
              </ImageList>
            </div>
          )}
          {reason === "REG_COPYRIGHT_MATCH" ||
            ("COPYRIGHT_MATCH" && (
              <FormControl
                fullWidth
                sx={{ mt: 1 }}
                label="Add new offical image URL:"
              >
                <TextField
                  fullWidth
                  size="small"
                  id="newImage"
                  placeholder="Add new offical image URL:"
                  value={newImage}
                  onChange={(event) => {
                    if (event.target.value.startsWith("http")) {
                      setNewImage(event.target.value);
                      setSelectedProductImage(event.target.value);
                    } else {
                      setNewImage(undefined);
                      setSelectedProductImage(undefined);
                    }
                  }}
                />
              </FormControl>
            ))}
          <FormGroup row>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="doNotFile"
                    onClick={(event) => setDoNotFile(event.target.checked)}
                  />
                }
                label="Do Not File Complaint"
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={submitDisabled}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {isUpdating && (
        <Backdrop
          open={true}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
