import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getStatus } from "../../../utils/labels";

export default function ConfirmationDialog(props) {
  const {
    open,
    handleClose,
    field,
    value,
    handleChange,
    comment,
    setComment,
    processLike,
    setProcessLike,
    selected,
    reason,
    setReason,
  } = props;

  const getState = (value) => {
    switch (value) {
      case "DE":
        return "Design Infringement";
      case "TM":
        return "Trademark Infringement";
      case "CR":
        return "Copyright Infringement";
      case "CFT":
        return "Counterfeit Infringement";
      case "AS":
        return "Authorized Seller";
      case "GE":
        return "Genuine";
      case "SU":
        return "Suspected Infringement";
      case "LA":
        return "Lookalike";
      case "IR":
        return "Irrelevant";
      case "SH":
        return "Seller Hold";
      case "US":
        return "Unauthorized Sale Infringement";
      default:
        return "Unknown";
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Listing Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Confirm change of listing ${field} to ${
              field === "state" ? getState(value) : getStatus(value)
            }. ${
              field === "state"
                ? "Enter a comment for this change below (optional)."
                : ""
            }`}
          </DialogContentText>
          {field === "state" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Comment"
              value={comment}
              type="text"
              fullWidth
              variant="standard"
              onChange={(event) => setComment(event.target.value)}
            />
          )}
          {value === "US" && (
            <div style={{ marginTop: 10 }}>
              <DialogContentText>
                Specify a reason for this classification (optional):
              </DialogContentText>
              <Select
                sx={{ mt: 1, mb: 4 }}
                fullWidth
                size="small"
                labelId="reason-select-label"
                id="reason-simple-select"
                value={reason}
                label="Reason"
                onChange={(event) => setReason(event.target.value)}
              >
                <MenuItem value={"UP"}>Prohibited Sale - Policy</MenuItem>
                <MenuItem value={"UR"}>
                  Prohibited Sale - Law & Regulation
                </MenuItem>
                <MenuItem value={"UU"}>Unenforceable - Suspect</MenuItem>
              </Select>
            </div>
          )}
          {selected.length === 1 && field === "state" && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    id="processLike"
                    value={processLike}
                    checked={processLike}
                    onChange={(e) => {
                      setProcessLike(e.target.checked);
                    }}
                  />
                }
                label="Apply to listings in all marketplace locales (where applicable)"
              />
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleChange}>CONFIRM</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
