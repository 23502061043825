const exportLink = new URL(
  `${window.location.protocol}//${window.location.hostname}/api/v1/store/listings/export/csv`
);
const SEPARATOR = ",";

export const getExportLink = (params, user, source) => {
  for (const [k, v] of Object.entries(params)) {
    if (v !== undefined && v !== null && v !== "") {
      let paramKey;
      let paramValue;
      switch (k) {
        case "brands":
          paramKey = "brand";
          paramValue = v;
          break;
        case "countries":
          paramKey = "country";
          paramValue = v;
          break;
        case "marketplaces":
          paramKey = "platform";
          paramValue = v;
          break;
        case "seller":
          paramKey = "seller";
          paramValue = v;
          break;
        case "state":
          paramKey = "state";
          paramValue = v;
          break;
        case "infringementTypes":
          paramKey = "toi";
          paramValue = v;
          break;
        case "listingStatus":
          paramKey = "listingStatus";
          paramValue = v;
          break;
        case "products":
          paramKey = "product";
          paramValue = v;
          break;
        case "value":
          paramKey = "contains";
          paramValue = v;
          break;
        case "field":
          paramKey = "field";
          paramValue = v;
          break;
        case "channel":
          paramKey = "channel";
          paramValue = v;
          break;
        case "tags":
          paramKey = "tags";
          paramValue = v;
          break;
        case "start":
          paramKey = "date";
          paramValue = new Date(v).toJSON();
          break;
        case "end":
          paramKey = "endDate";
          paramValue = new Date(v).toJSON();
          break;
        case "dateField":
          if (source === "inbox") {
            paramKey = k;
            paramValue = v.toLowerCase();
          }
          break;

        default:
          paramKey = k;
          paramValue = v;
          break;
      }

      if (
        (Array.isArray(paramValue) && paramValue.length !== 0) ||
        (typeof paramValue === "string" && paramValue.length !== 0)
      ) {
        exportLink.searchParams.set(
          paramKey,
          Array.isArray(paramValue) ? paramValue.join(SEPARATOR) : paramValue
        );
      }
    }
  }
  exportLink.searchParams.set("jwt", user?.auth || "");
  return exportLink;
};
